<template>

  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

a{
  text-decoration: none;
  color: white;
}
</style>
